<template>
  <div class="buylist-style">
    <titles :title="$t('text.t356')" />
    <div class="types start-center">
      <div class="tlist start-center">
        <div class="titem" :class="type==0?'ac':'nos'" @click="changes(0)">{{$t('text.t199')}}</div>
        <div class="titem" :class="type==1?'ac':'nos'" @click="changes(1)">{{$t('text.t385')}}</div>
      </div>
    </div>
    <div class="list" v-if="type == 0">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('text.t39')"
        :offset="10"
        @load="getlist"
        ref="list"
      >
        <div class="item" v-for="item in list" :key="item.id">
          <div class="row2 between-center">
            <img :src="$http.imgurl + item.image" class="i2" />
            <div class="zhong">
              <div class="zt1">True NFT #{{ item.tokenid }}</div>
              <div class="zt2">{{ $parseTime(item.createtime) }}</div>
            </div>
            <div class="right">
              <div class="rt1">{{item.actual}} OMT</div>
              <div class="rt2">{{$t('text.t357')}}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <div class="list" v-else-if="type == 1">
      <van-list
        v-model="loading1"
        :finished="finished1"
        :finished-text="$t('text.t39')"
        :offset="10"
        @load="getlist1"
        ref="list1"
      >
        <div class="item" v-for="item in list1" :key="item.id">
          <div class="row2 between-center">
            <img :src="$http.imgurl + item.image" class="i2" />
            <div class="zhong">
              <div class="zt1">True NFT #{{ item.tokenid }}</div>
              <div class="addt1 addt2">{{$t('text.t386')}}:{{item.surplus}} OMT</div>
              <div class="addt1">{{$t('text.t384')}}{{ item.quota }} OMT</div>
            </div>
            <div class="right">
              <div class="rt1 rt2">{{$t('text.t387')}}:{{item.actual}} OMT</div>
              <div class="addt1 addt2">{{$t('text.t375')}}{{item.flag}}{{$t('text.t376')}}</div>
              <div class="zt2">{{ $parseTime(item.createtime) }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
import titles from "@/components/titles/index.vue";
export default {
  components: {
    titles,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,
      type:0,

      list1: [],
      loading1: false,
      finished1: false,
      page1: 1,
    };
  },
  mounted() {},
  methods: {
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit
      };
      this.$http.get("/release/orderList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        this.list = [...this.list, ...data];
      });
    },
    getlist1() {
      let arg = {
        page: this.page1,
        limit: this.limit,
      };
      this.$http.get("/release/logList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished1 = true;
        this.page1++;
        this.loading1 = false;
        this.list1 = [...this.list1, ...data];
      });
    },
    changes(e){
      this.type = e
      this.$nextTick(()=>{
        if(e == 1){
          this.$refs.list1.check()
        } else{
          this.$refs.list.check()
        }
      })
    }
  },
};
</script>

<style lang="less">
.buylist-style {
  .types{
    padding: 20px 20px 0;
    .tlist{
      background: #F4F4F4;
      border: 1px solid #E1E1E1;
      border-radius: 5px;
      padding: 1px 2px;
      .titem{
        border-radius: 5px;
        padding: 9px 20px;
        font-size: 12px;
        font-weight: 500;
        color: #323232;
      }
      .ac{
        background: #FFFFFF;
      }
      .nos{
        background: #F4F4F4;
      }
    }
  }
  .list {
    .item {
      padding: 20px;
      border-bottom: 1px solid #e1e1e1;
      .row2 {
        .i2 {
          width: 41px;
          height: auto;
          margin-right: 10px;
        }
        .zhong{
          flex: auto;
          .zt1{
            font-size: 15px;
            font-weight: bold;
            color: #323232;
            margin-bottom: 13px;
          }
          
          .zt3{
            font-size: 12px;
          }
          
        }
        .zt2{
          font-size: 12px;
          font-weight: 500;
          color: #909090;
        }
        .right{
          font-weight: bold;
          color: #323232;
          text-align: right;
          .rt1{
            font-size: 15px;
            margin-bottom: 13px;
          }
          .rt2{
            font-size: 12px;
            span{
              color: #0F7AFF;
            }
          }
        }
        .addt1{
          font-size: 12px;
          font-weight: bold;
          color: #323232;
        }
        .addt2{
          margin-bottom: 10px;
        }

        .t3 {
          flex: auto;
          font-size: 15px;
          font-weight: bold;
          color: #323232;
        }
        .b1 {
          margin-left: 10px;
          font-size: 12px;
          font-weight: 500;
          color: #323232;
        }
      }
    }
  }
}
</style>